import React, { useCallback, useEffect, useState } from 'react';
import {
  Button, Card, CardBody, CardFooter, CardHeader, CardText, CardTitle,
  Col, FormGroup, Input, InputGroup, InputGroupText,
  Modal, ModalHeader, ModalBody, ModalFooter, Label
} from 'reactstrap';
import MaskedInput from 'react-text-mask'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { format } from 'date-fns'

const props = {
  guide: false,
  placeholderChar: '\u2000',
  mask: value => Array(value.length).fill(/[\d.]/),
  pipe: value => {
    if (value === '.' || value.endsWith('..')) return false;

    const parts = value.split('.');

    if (
      parts.length > 4 ||
      parts.some(part => part === '00' || part < 0 || part > 255)
    ) {
      return false;
    }

    return value;
  },
};

function App() {
  const [clearAllOk, setClearAllOk] = useState(false);
  const [modalIndex, setModalIndex] = useState(false);
  const [modalDeleteIndex, setModalDeleteIndex] = useState(false);
  const [modalDayOfSynchronize, setModalDayOfSynchronize] = useState(false);

  const [position, setPosition] = useState('0');
  const [chapa, setChapa] = useState('0');
  const [dateSynchronize, setDateSynchronize] = useState(format(new Date(), 'dd/MM/yyyy'));

  const [storedFinger, setStoredFinger] = useState(false);
  const [checkFinger, setCheckFinger] = useState(false);
  const [fingerPrints, setFingerPrints] = useState(0);
  const [statusFingers, setStatusFingers] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [deleteFinger, setDeleteFinger] = useState(false);
  const [dataSynchronizedOk, setDataSynchronizedOk] = useState(false);

  const [socketUrl, setSocketUrl] = useState('ws://192.168.15.36:8081/');
  const [ip, setIP] = useState('192.168.15.36');

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => setIsConnected(true),
    onClose: () => setIsConnected(false),
    shouldReconnect: (closeEvent) => true,
  });

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Conectando...',
    [ReadyState.OPEN]: 'Conectado!',
    [ReadyState.CLOSING]: 'Desconectando...',
    [ReadyState.CLOSED]: 'Desconectado!',
    [ReadyState.UNINSTANTIATED]: 'Não instanciado!',
  }[readyState];

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const { data } = lastMessage;
      console.log(data);
      if (data.includes('{')) {
        const { response } = JSON.parse(data);
        if (response.startsWith('FINGERPRINT_WITH_CONFIDENCE')) {
          const parts = response.split("|");
          setCheckFinger(true);
          toast(`Digital Cadastrada na Posição ${parts[3]}!!!`);
        } else if (response.startsWith('CHECK_FINGER_PRINT_NOK')) {
          toast.error('Digital não Cadastrada!!!');
          setCheckFinger(false);
        } else if (response.startsWith('REGISTERED_FINGERPRINTS')) {
          const parts = response.split("|");
          setFingerPrints(parseInt(parts[1]));
          setStatusFingers(true);
        } else if (response.startsWith('FINGER_STORE_MODEL_OK')) {
          setStoredFinger(true);
        } else if (response.startsWith('CLEAR_ALL_FINGER_PRINT_OK')) {
          setClearAllOk(true);
          toast('Dados Excluídos!!!');
        } else if (response.startsWith('CLEAR_ALL_FINGER_PRINT_NOK')) {
          setClearAllOk(false);
          toast.error('Erro ao Excluir Dados!!!');
        } else if (response.startsWith('DELETE_POSITION_OK')) {
          setDeleteFinger(true);
        } else if (response.startsWith('SYNCHRONIZE_FINGERPRINTS_COLLECTED_OK')) {
          setDataSynchronizedOk(true);
          toast('Dados Sincronizados!!!');
        }
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    toast(`IP Alterado para ${socketUrl}.`);
  }, [socketUrl]);

  const toggle = () => setModalIndex(!modalIndex);
  const toggleDelete = () => setModalDeleteIndex(!modalDeleteIndex);
  const toggleDayOfSynchronize = () => setModalDayOfSynchronize(!modalDayOfSynchronize);

  const storeIndexFingerCommand = useCallback(() => {
    try {
      const index = parseInt(position);
      if (index > 0 && index <= 160) {
        const obj = {
          command: "STORE_FINGER_PRINT",
          params: `${index}`,
          value: `${chapa}`,
          period: ``,
        }
        sendMessage(JSON.stringify(obj));
        setModalIndex(false);
        setPosition('0');
        setChapa('0');
      }
    } catch (err) {
      console.log(err);
      setPosition(0);
    }
  }, [sendMessage, chapa, position]);

  const storedFingerCommand = useCallback(() => {
    setStatusFingers(false);
    sendMessage(`{"command": "REGISTERED_FINGERPRINTS", "params": "", "value": "", "period": "" }`);
  }, [sendMessage, setStatusFingers]);

  const checkFingerCommand = useCallback(() => {
    sendMessage(`{ "command": "CHECK_FINGER_PRINT", "params": "", "value": "", "period": ""  }`);
  }, [sendMessage]);

  const deleteFingerCommand = useCallback(() => {
    try {
      const index = parseInt(position);
      if (index > 0 && index <= 160) {
        const obj = {
          command: "DELETE_FINGER_PRINT",
          params: `${index}`,
          value: `${chapa}`,
          period: ``,
        }
        sendMessage(JSON.stringify(obj));
        setModalDeleteIndex(false);
        setPosition('0');
        setChapa('0');
      }
    } catch (err) {
      console.log(err);
      setPosition(0);
    }
  }, [sendMessage, chapa, position]);

  const clearAllFingerCommand = useCallback(() => {
    sendMessage(`{ "command": "CLEAR_ALL_FINGER_PRINT", "params": "", "value": "", "period": "" }`)
  }, [sendMessage]);

  const handleChangePosition = (e) => {
    const { value } = e.target;
    setPosition(value);
  }

  const handleChapaPosition = (e) => {
    const { value } = e.target;
    setChapa(value);
  }

  const synchronizeFingerCommand = useCallback(() => {
    sendMessage(`{ "command": "SYNCHRONIZE_FINGERPRINTS_COLLECTED", "params": "", "value": "", "period": "${dateSynchronize.split('/').join('-')}" }`);
  }, [sendMessage, dateSynchronize]);

  const handleConnect = () => {
    setSocketUrl(`ws://${ip}:8081/`);
  }

  const handlChangeIP = (e) => {
    const value = e.target.value;
    setIP(value);
  }

  const handleModalIndex = () => {
    // storeFingerCommand
    setModalIndex(true);
    setStoredFinger(false);
  }

  const handleConfirmDelete = () => {
    confirmAlert({
      title: 'Atenção!',
      message: 'Confirma limpar todas as digitais cadastradas?',
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => { clearAllFingerCommand(); }
        },
        {
          label: 'Cancelar',
          onClick: () => console.log('cancelado...')
        }
      ]
    });
  };

  const handleShowDeleteIndex = () => {
    setModalDeleteIndex(true);
  }

  const handleModalDayOfSynchronize = () => {
    setDateSynchronize(format(new Date(), 'dd/MM/yyyy'));
    setModalDayOfSynchronize(true);
  }

  const handleDateSynchronize = (e) => {
    const { value } = e.target;
    setDateSynchronize(value);
  }

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="colored"
      />
      <header className="App-header">
        <Card
          style={{
            width: '48rem'
          }}
        >
          <CardHeader>
            CONFIGURAÇÕES DO COLETOR BIOMÉTRICO
          </CardHeader>
          <CardBody>
            <CardTitle tag="h5" style={{ marginBottom: 20 }}>
              ENVIE O COMANDO DESEJADO E AGUARDE O RETORNO!
            </CardTitle>

            <FormGroup row>
              <Col sm={6}>
                <InputGroup >
                  <Button color="success" style={{
                    width: '16rem',
                    height: '2.4rem',
                  }}
                    onClick={handleModalIndex}
                    disabled={!isConnected}
                  >
                    CADASTRAR DIGITAL
                  </Button>

                  <InputGroupText style={{ width: '3.4rem', justifyContent: 'center', backgroundColor: storedFinger ? '#20c997' : '#e9ecef' }}>
                    {storedFinger ? 'OK' : 'N/E'}
                  </InputGroupText>
                </InputGroup>
              </Col>
              <Col sm={6}>
                <InputGroup >
                  <Button color="success" style={{
                    width: '16rem',
                    height: '2.4rem',
                  }}
                    onClick={checkFingerCommand}
                    disabled={!isConnected}
                  >
                    CONFERIR DIGITAL
                  </Button>
                  <InputGroupText style={{ width: '3.4rem', justifyContent: 'center', backgroundColor: checkFinger ? '#20c997' : '#e9ecef' }}>
                    {checkFinger ? 'OK' : 'N/E'}
                  </InputGroupText>
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={6}>
                <InputGroup>
                  <Button color="success" style={{
                    width: '16rem',
                    height: '2.4rem',
                  }}
                    onClick={storedFingerCommand}
                    disabled={!isConnected}
                  >
                    QUANTIDADE DE DIGITAIS
                  </Button>
                  <InputGroupText style={{ width: '3.4rem', justifyContent: 'center', backgroundColor: statusFingers ? '#20c997' : '#e9ecef' }}>
                    {fingerPrints}
                  </InputGroupText>
                </InputGroup>
              </Col>
              <Col sm={6}>
                <InputGroup>
                  <Button color="success" style={{
                    width: '16rem',
                    height: '2.4rem',
                  }}
                    onClick={handleShowDeleteIndex}
                    disabled={!isConnected}
                  >
                    EXCLUIR POSIÇÃO
                  </Button>
                  <InputGroupText style={{ width: '3.4rem', justifyContent: 'center', backgroundColor: deleteFinger ? '#20c997' : '#e9ecef' }}>
                    {deleteFinger ? 'OK' : 'N/E'}
                  </InputGroupText>
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm={6}>
                <InputGroup>
                  <Button color="success" style={{
                    width: '16rem',
                    height: '2.4rem',
                  }}
                    onClick={handleConfirmDelete}
                    disabled={!isConnected}
                  >
                    EXLUIR TODAS AS DIGITAIS
                  </Button>
                  <InputGroupText style={{ width: '3.4rem', justifyContent: 'center', backgroundColor: clearAllOk ? '#20c997' : '#e9ecef' }}>
                    {clearAllOk ? 'OK' : 'N/E'}
                  </InputGroupText>
                </InputGroup>
              </Col>
              <Col sm={6}>
                <InputGroup>
                  <Button color="success" style={{
                    width: '16rem',
                    height: '2.4rem',
                  }}
                    onClick={handleModalDayOfSynchronize}
                    disabled={!isConnected}
                  >
                    SINCRONIZAR COM SERVIDOR
                  </Button>
                  <InputGroupText style={{ width: '3.4rem', justifyContent: 'center', backgroundColor: dataSynchronizedOk ? '#20c997' : '#e9ecef' }}>
                    N/E
                  </InputGroupText>
                </InputGroup>
              </Col>
            </FormGroup>
          </CardBody>
          <CardFooter>
            <CardText>
              <MaskedInput {...props} value={ip} onChange={handlChangeIP} style={{ textAlign: 'center' }} className='form-control' />
              <Button style={{ marginTop: 10, marginBottom: 10 }} onClick={handleConnect} >CONFIRMAR IP</Button>
            </CardText>
          </CardFooter>
          <CardFooter>
            <CardText>
              {connectionStatus.toUpperCase()}
            </CardText>
          </CardFooter>
        </Card>
      </header>

      {modalIndex && <Modal isOpen={modalIndex} toggle={toggle}>
        <ModalHeader toggle={toggle}>Informe a Posição a Cadastrar</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="position">
              Informe uma Posição Válida entre 1 e 160
            </Label>
            <Input
              id="position"
              name="position"
              placeholder="posição entre 1 e 160"
              type="text"
              maxLength={3}
              value={position}
              onChange={handleChangePosition}
            />
          </FormGroup>

          <FormGroup>
            <Label for="position">Informe um número de Chapa Válido</Label>
            <Input
              id="position"
              name="position"
              placeholder="informe um número de chapa válido"
              type="text"
              maxLength={5}
              value={chapa}
              onChange={handleChapaPosition}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => storeIndexFingerCommand()}>
            Confirmar
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>}

      {modalDeleteIndex && <Modal isOpen={modalDeleteIndex} toggle={toggleDelete}>
        <ModalHeader toggle={toggleDelete}>Informe a Posição a Excluir</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="position">
              Informe uma Posição Válida entre 1 e 160
            </Label>
            <Input
              id="position"
              name="position"
              placeholder="posição entre 1 e 160"
              type="text"
              maxLength={3}
              value={position}
              onChange={handleChangePosition}
            />
          </FormGroup>

          <FormGroup>
            <Label for="position">Informe um número de Chapa Válido</Label>
            <Input
              id="position"
              name="position"
              placeholder="informe um número de chapa válido"
              type="text"
              maxLength={5}
              value={chapa}
              onChange={handleChapaPosition}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => deleteFingerCommand()}>
            Confirmar
          </Button>{' '}
          <Button color="secondary" onClick={toggleDelete}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>}

      {modalDayOfSynchronize && <Modal isOpen={modalDayOfSynchronize} toggle={toggleDayOfSynchronize}>
        <ModalHeader toggle={toggleDayOfSynchronize}>Informe a Data para Sincronizar</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="dateSynchronize">Informe uma Data Válida para Sincronizar</Label>
            <MaskedInput
              id="dateSynchronize"
              name="dateSynchronize"
              className='form-control'
              mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              value={dateSynchronize}
              onChange={handleDateSynchronize}
              placeholder="data válida para Sincronizar"
              style={{ textAlign: 'center' }}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => synchronizeFingerCommand()}>
            Confirmar
          </Button>{' '}
          <Button color="secondary" onClick={toggleDayOfSynchronize}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>}
    </div>
  );
}

export default App;
